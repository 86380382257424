<template>
  <v-container
    :id="formId"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mt-3 pl-4"
        cols="12"
        sm="12"
        md="4"
      />
      <v-col
        class="px-3"
        cols="12"
        sm="6"
        md="4"
      >
        <v-text-field
          id="search"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('dataTable.SEARCH')"
          single-line
          hide-details
          clearable
          clear-icon="mdi-close"
        />
      </v-col>
      <v-col
        class="mb-2 mt-2 pr-2"
        cols="12"
        sm="6"
        md="4"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
          tag="form"
          @submit.prevent="submit()"
        >
          <v-dialog
            v-model="dialog"
            max-width="800px"
            content-class="dlgNewEditItem"
          >
            <template
              v-if="user.role === 'Administrateur'"
              v-slot:activator="{ on }"
            >
              <div class="text-right">
                <v-btn
                  color="secondary"
                  class="btnNewItem"
                  v-on="on"
                >
                  <v-icon class="mr-2">
                    mdi-plus
                  </v-icon>
                  {{ $t('contacts.NEW_ELEMENT') }}
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <!--                    <template v-if="editedItem._id">-->
                  <!--                      <v-col-->
                  <!--                        cols="12"-->
                  <!--                        md="6"-->
                  <!--                      >-->
                  <!--                        <label for="createdtime">{{-->
                  <!--                          $t('common.CREATED')-->
                  <!--                        }}</label>-->
                  <!--                        <div name="createdtime">-->
                  <!--                          {{ getFormat(editedItem.createdtime) }}-->
                  <!--                        </div>-->
                  <!--                      </v-col>-->
                  <!--                      <v-col-->
                  <!--                        cols="12"-->
                  <!--                        md="6"-->
                  <!--                      >-->
                  <!--                        <label for="modifiedtime">{{-->
                  <!--                          $t('common.UPDATED')-->
                  <!--                        }}</label>-->
                  <!--                        <div name="modifiedtime">-->
                  <!--                          {{ getFormat(editedItem.modifiedtime) }}-->
                  <!--                        </div>-->
                  <!--                      </v-col>-->
                  <!--                    </template>-->
                  <template v-if="user.role != 'Administrateur'">
                    <v-row>
                      <v-col cols="12">
                        <v-row v-if="editedItem._id">
                          <v-col
                            v-for="field in schema.fields"
                            :key="field.name"
                            cols="6"
                          >
                            <div v-if="field.type.name == 'reference' || field.type.name == 'owner'">
                              <strong>{{ field.label }}</strong><br>
                              <span v-if="editedItem[field.name].label">{{ editedItem[field.name].label }}</span>
                              <span
                                v-else
                                style="color:#cccccc"
                              >N.C.</span>
                            </div>
                            <div v-else-if="field.type.name == 'picklist' || field.type.name == 'multipicklist'">
                              <strong>{{ field.label }}</strong><br>
                              <span v-if="editedItem[field.name]">{{ editedItem[field.name] }}</span>
                              <span
                                v-else
                                style="color:#cccccc"
                              >N.C.</span>
                            </div>
                            <div v-else-if="field.type.name == 'datetime'">
                              <strong>{{ field.label }}</strong><br>
                              <span v-if="editedItem[field.name]">{{ editedItem[field.name] }}</span>
                              <span
                                v-else
                                style="color:#cccccc"
                              >N.C.</span>
                            </div>
                            <div v-else-if="field.type.name == 'boolean'">
                              <strong>{{ field.label }}</strong><br>
                              <span v-if="editedItem[field.name]">{{ editedItem[field.name] }}</span>
                              <span
                                v-else
                                style="color:#cccccc"
                              >N.C.</span>
                            </div>
                            <div v-else>
                              <strong>{{ field.label }}</strong><br>
                              <span v-if="editedItem[field.name]">{{ editedItem[field.name] }}</span>
                              <span
                                v-else
                                style="color:#cccccc"
                              >N.C.</span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-if="user.role === 'Administrateur'">
                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-select
                            id="account_id"
                            v-model="editedItem.account_id.value"
                            :error-messages="errors"
                            required
                            :items="accounts"
                            name="account_id"
                            item-text="accountname"
                            item-value="_id"
                            label="Société"
                            prepend-icon="mdi-domain"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            id="email"
                            v-model="editedItem.email"
                            required
                            name="email"
                            :label="$t('E-mail')"
                            :error="errors.length > 0"
                            :error-messages="errors[0]"
                            autocomplete="off"
                            prepend-icon="mdi-at"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            id="firstname"
                            v-model="editedItem.firstname"
                            required
                            name="firstname"
                            :label="$t('Prénom')"
                            :error="errors.length > 0"
                            :error-messages="errors[0]"
                            autocomplete="off"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            id="lastname"
                            v-model="editedItem.lastname"
                            required
                            name="lastname"
                            :label="$t('Nom')"
                            :error="errors.length > 0"
                            :error-messages="errors[0]"
                            autocomplete="off"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          id="phone"
                          v-model="editedItem.phone"
                          required
                          name="phone"
                          :label="$t('Téléphone')"
                          autocomplete="off"
                          prepend-icon="mdi-phone"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          id="mobile"
                          v-model="editedItem.mobile"
                          required
                          name="mobile"
                          :label="$t('Mobile')"
                          autocomplete="off"
                          prepend-icon="mdi-cellphone-basic"
                        />
                      </v-col>
                      <v-col cols="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            id="title"
                            v-model="editedItem.title"
                            required
                            name="title"
                            :label="$t('Fonction')"
                            :error="errors.length > 0"
                            :error-messages="errors[0]"
                            autocomplete="off"
                            prepend-icon="mdi-briefcase-outline"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="6">
                        <v-switch
                          v-model="portal"
                          label="Accès portail"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="portal === true">
                      <v-col cols="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-select
                            :items="roles"
                            v-model="editedItem.cf_1830"
                            label="Rôle"
                            :error="errors.length > 0"
                            :error-messages="errors[0]"
                            prepend-icon="mdi-lock-open-check-outline"
                          ></v-select>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </template>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="red lighten3"
                  text
                  class="btnCancel"
                  @click="close"
                >
                  {{ $t('dataTable.CANCEL') }}
                </v-btn>
                <v-btn
                  color="green lighten3"
                  text
                  class="btnSave"
                  :disabled="invalid"
                  @click="save"
                >
                  {{ $t('dataTable.SAVE') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </ValidationObserver>
      </v-col>
    </v-row>
    <base-material-card
      icon="mdi-domain"
      :title="$t(dataTableTitle)"
      class="px-5 py-3"
    >
      <v-data-table
        :loading="dataTableLoading"
        :no-data-text="$t('dataTable.NO_DATA')"
        :no-results-text="$t('dataTable.NO_RESULTS')"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :items-per-page="10"
        :server-items-length="totalItems"
        class="elevation-1"
        :footer-props="{
          'items-per-page-text': $t('dataTable.ROWS_PER_PAGE'),
          'items-per-page-options': [5, 10, 25]
        }"
      >
        <template v-slot:[`item._id`]="{ item }">
          <v-row class="justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  id="edit"
                  icon
                  v-on="on"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('dataTable.VIEW') }}</span>
            </v-tooltip>
          </v-row>
        </template>
        <template v-slot:[`item.createdtime`]="{ item }">
          {{ getFormat(item.createdtime) }}
        </template>
        <template v-slot:[`item.modifiedtime`]="{ item }">
          {{ getFormat(item.modifiedtime) }}
        </template>
        <template
          v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
        >
          {{ pageStart }} - {{ pageStop }}
          {{ $t('dataTable.OF') }}
          {{ itemsLength }}
        </template>
        <template v-slot:no-data>
          {{ $t('dataTable.NO_DATA') }}
        </template>
        <template v-slot:no-results>
          {{ $t('dataTable.NO_RESULTS') }}
        </template>
      </v-data-table>
      <ErrorMessage />
      <SuccessMessage />
    </base-material-card>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { getFormat, buildPayloadPagination } from '@/utils/utils.js'
  import router from '@/router'

  export default {
    metaInfo () {
      return {
        title: this.$store.getters.appTitle,
        titleTemplate: `${this.$t(this.getModule().toLowerCase() + '.' + this.getModule().toUpperCase())} - %s`,
      }
    },
    data () {
      return {
        dataTableLoading: true,
        delayTimer: null,
        dialog: false,
        search: '',
        pagination: {},
        editedItem: {},
        defaultItem: {
          account_id: {
            label: '',
            value: '',
          },
          portal: false,
        },
        fieldsToSearch: [],
        schema: {},
        headers: [],
        headerSet: false,
        accounts: [],
      }
    },
    computed: {
      ...mapGetters(['user']),
      portal: {
        get: function () {
          if (this.editedItem.portal === '1') {
            return true
          } else {
            return false
          }
        },
        set: function (newValue) {
          if (newValue === true) {
            this.editedItem.portal = '1'
          } else {
            this.editedItem.portal = '0'
          }
        },
      },
      roles () {
        if (this.user._id === this.editedItem._id) {
          return ['Administrateur', 'Responsable', 'Collaborateur']
        } else {
          return ['Responsable', 'Collaborateur']
        }
      },
      formId () {
        return this.getModule().toLowerCase()
      },
      formTitle () {
        return this.editedItem._id
          ? this.editedItem.firstname + ' ' + this.editedItem.lastname
          : this.$t('contacts.NEW_ELEMENT')
      },
      dataTableTitle () {
        return `${this.$t(this.getModule().toLowerCase() + '.' + this.getModule().toUpperCase())}`
      },
      items () {
        // console.log(this.$store.state.records.records)
        return this.$store.state.records.records
      },
      totalItems () {
        return this.$store.state.records.totalRecords
      },
    },
    watch: {
      dialog (value) {
        return value ? true : this.close()
      },
      pagination: {
        async handler () {
          try {
            this.dataTableLoading = true
            await this.getRecords(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            if (!this.headerSet) {
              await this.getSchema()
              this.schema = this.$store.state.schema.schema
              this.fieldsToSearch.push(this.schema.labelFields)
              this.setHeaders()
            }

            // Initialisation des valeurs par défaut
            this.defaultItem.account_id.value = this.user._account_id
            this.editedItem = Object.assign({}, this.defaultItem)

            // Chargement des comptes
            await this.getAccounts()
            this.accounts = this.$store.state.dependencies.accounts

            this.dataTableLoading = false
          // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true,
      },
      async search () {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
          this.doSearch()
        }, 400)
      },
    },
    mounted () {
    // console.log('mounted')
    },
    created () {
    // console.log('created')
    },
    updated () {
    // console.log('updated')
    },
    methods: {
      ...mapActions(['getRecords', 'editRecord', 'saveRecord', 'deleteRecord', 'getSchema', 'getAccounts']),
      getModule () {
        const module = router.getRoutes().find(o => o.path === router.currentRoute.path).props.default.module
        return module
      },
      getFormat (date, hour = true) {
        window.__localeId__ = this.$store.getters.locale
        if (date.length > 0 && !/^0000-00-00/.test(date)) {
          if (hour) {
            return getFormat(date, 'dd/MM/yyyy HH:mm')
          } else {
            return getFormat(date, 'dd/MM/yyyy')
          }
        } else {
          return ''
        }
      },
      setHeaders () {
        this.headers = [
          {
            text: this.$i18n.t('dataTable.ACTIONS'),
            value: '_id',
            sortable: false,
            width: 100,
          },
        ]
        for (const element of Object.entries(this.schema.list)) {
          const field = element[1]
          if (field.type.name === 'owner') {
            continue
          }
          this.headers.push({
            text: this.$i18n.t(field.label),
            value: (field.type.name === 'reference') ? field.name + '.label' : field.name,
            sortable: true,
          })
        }
        this.headerSet = true
      },
      async doSearch () {
        try {
          this.dataTableLoading = true
          await this.getRecords(
            buildPayloadPagination(this.pagination, this.buildSearch()),
          )
          this.dataTableLoading = false
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
        }
      },
      buildSearch () {
        return this.search
          ? { query: this.search, fields: this.fieldsToSearch.join(',') }
          : {}
      },
      async editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          const response = await this.$confirm(
            this.$t('common.DO_YOU_REALLY_WANT_TO_DELETE_THIS_ITEM'),
            {
              title: this.$t('common.WARNING'),
              buttonTrueText: this.$t('common.DELETE'),
              buttonFalseText: this.$t('common.CANCEL'),
              buttonTrueColor: 'red lighten3',
              buttonFalseColor: 'green',
            },
          )
          if (response) {
            this.dataTableLoading = true
            await this.deleteRecord(item._id)
            await this.getRecords(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            this.dataTableLoading = false
          }
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },
      async save () {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          try {
            this.dataTableLoading = true
            // Updating item
            if (this.editedItem._id) {
              await this.editRecord(this.editedItem)
              await this.getRecords(
                buildPayloadPagination(this.pagination, this.buildSearch()),
              )
              this.dataTableLoading = false
            } else {
              // Creating new item
              await this.saveRecord(this.editedItem)
              await this.getRecords(
                buildPayloadPagination(this.pagination, this.buildSearch()),
              )
              this.dataTableLoading = false
            }
            this.close()
          // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
            this.close()
          }
        }
      },
    },
  }
</script>

<style>
table.v-table {
  max-width: none;
}
</style>
